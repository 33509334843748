<template>
  <section>

    <validation-observer ref="createUser">
      <b-overlay
        :show="isAPICallInProgress"
        rounded="sm"
      >
        <b-card>
          <b-form
            class="form-validate"
          >
            <b-row class="mb-2">
              <b-col
                cols="12"
                class="my-2"
              >
                <b-card-header class="p-1">
                  <b-card-title class="font-medium-2">
                    <feather-icon
                      icon="UserIcon"
                      size="18"
                    />
                    <span class="align-middle ml-50">Informações do novo usuário</span>
                  </b-card-title>
                </b-card-header>
              </b-col>
              <b-col
                cols="4"
              >
                <b-form-group
                  label="Nome*"
                  label-for="name"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="nome"
                    rules="required"
                  >
                    <b-form-input
                      v-model="name"
                      :state="errors.length > 0 ? false:null"
                      type="text"
                      placeholder="Eleanor Aguiar"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col
                cols="4"
              >
                <b-form-group
                  label="Email*"
                  label-for="emailUser"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="email"
                    rules="required|email"
                  >
                    <b-form-input
                      v-model="emailUser"
                      :state="errors.length > 0 ? false:null"
                      type="text"
                      placeholder="eleanoraguiar@gmail.com"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col
                cols="4"
              >
                <b-form-group
                  label="CPF"
                  label-for="cpf"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="cpf"
                  >
                    <cleave
                      id="cpf"
                      v-model="cpf"
                      class="form-control"
                      :raw="false"
                      :options="cpfMask"
                      placeholder="xxx.xxx.xxx-xx"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col
                cols="4"
              >
                <b-form-group
                  label="Telefone"
                  label-for="telefone"
                >
                  <cleave
                    id="cellPhone"
                    v-model="phone"
                    class="form-control"
                    :raw="false"
                    :options="cellPhoneMask"
                    placeholder="(xx) xxxxx-xxxx"
                  />
                </b-form-group>
              </b-col>

              <b-col
                cols="8"
                class="d-flex py-1 justify-content-end"
              >

                <b-button
                  variant="primary"
                  type="submit"
                  @click.prevent="validationForm"
                >
                  <span class="text-nowrap">Criar usuário</span>
                  <feather-icon
                    icon="ArrowRightIcon"
                    size="18"
                  />
                </b-button>
              </b-col>

            </b-row>
          </b-form>

          <!--  -->
          <b-row>
            <b-col cols="12">
              <!-- PERMISSION TABLE -->
              <b-card
                no-body
                class="border mt-1"
              >
                <b-row class="pt-2">
                  <b-col cols="3">
                    <b-card-header class="p-1">
                      <b-card-title class="font-medium-2">
                        <feather-icon
                          icon="LockIcon"
                          size="18"
                        />
                        <span class="align-middle ml-50">Permissões</span>
                      </b-card-title>
                    </b-card-header>
                  </b-col>
                  <b-col cols="3">
                    <validation-observer ref="profileValidation">
                      <b-form
                        class="form-validate"
                      >
                        <b-form-group
                          label="Escolha o perfil"
                          label-for="profileName"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="nome de perfil"
                            rules="required"
                          >
                            <v-select
                              v-model="selected"
                              label="name"
                              :options="optionsProfileSelect"
                              :clearable="false"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-form>
                    </validation-observer>
                  </b-col>

                  <b-col cols="12">
                    <b-table
                      striped
                      responsive
                      class="mb-0"
                      :items="permissionsData"
                      :fields="tableColumns"
                      :sort-desc.sync="sortDesc"
                      :sort-by.sync="sortBy"
                    >
                      <template #cell(featureName)="data">
                        {{ data.value }}
                      </template>
                      <template #cell(edit)="data">
                        <b-form-checkbox
                          :checked="data.value"
                          disabled
                        />
                      </template>
                      <template #cell(read)="data">
                        <b-form-checkbox
                          :checked="data.value"
                          disabled
                        />
                      </template>
                    </b-table>
                  </b-col>
                </b-row>
              <!--  -->
              </b-card>
            </b-col>
          </b-row>
          <!--  -->
        </b-card>
      </b-overlay>

    </validation-observer>
  </section>

</template>

<script>

import { mapGetters } from 'vuex'

import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import Cleave from 'vue-cleave-component'
import removeSpecialChars from '@/utils/removeSpecialChars'

import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BCard, BButton, BCardHeader, BCardTitle, BFormCheckbox, BTable, BOverlay,
} from 'bootstrap-vue'

import { ValidationObserver, ValidationProvider, localize } from 'vee-validate'
import { required, email, min } from '@validations'

import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import userStoreModule from '../userStoreModule'

export default {
  components: {
    BRow,
    BCol,
    // BImg,
    BFormGroup,
    BFormInput,
    BForm,
    ValidationProvider,
    ValidationObserver,
    BButton,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BTable,
    BOverlay,
    vSelect,
    Cleave,
  },
  data() {
    return {
      required,
      email,
      min,
      name: '',
      emailUser: '',
      cpf: '',
      phone: '',
      newPasswordValue: '',
      RetypePassword: '',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',
      selected: '',
      sortDesc: false,
      sortBy: 'featureName',
      userData: JSON.parse(localStorage.getItem('userData')),
      cpfMask: {
        delimiters: ['.', '.', '-'],
        blocks: [3, 3, 3, 2],
        uppercase: true,
      },
      cellPhoneMask: {
        delimiters: ['(', ')', ' ', '-'],
        blocks: [0, 2, 0, 5, 4],
        uppercase: true,
      },
    }
  },
  computed: {
    ...mapGetters({
      isAPICallInProgress: 'api/isAPICallInProgress',
    }),
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  watch: {
    selected() {
      this.resolveSelectedProfile()
    },
  },
  mounted() {
    localize('pt_BR')

    this.fetchProfileList()
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    // itens do select de perfil
    const optionsProfileSelect = ref([])
    const qtdUserByProfile = ref(0)
    const permissionsData = ref([])

    const tableColumns = [
      { key: 'featureName', label: 'Funcionalidade', sortable: false },
      { key: 'edit', label: 'Editar', sortable: false },
      { key: 'read', label: 'Ver', sortable: false },
    ]

    // fetch lista de profiles
    function fetchProfileList() {
      return new Promise((resolve, reject) => {
        // usando a api via jwt
        useJwt.listProfile()
          .then(response => {
            this.profileList = response.data.result
            this.selected = { name: response.data.result[0].name, id: response.data.result[0].id }
            optionsProfileSelect.value = response.data.result.map(profile => ({ name: profile.name, id: profile.id }))

            return resolve(response)
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Ocorreu um erro ao tentar listar os perfis. Tente novamente.',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
              },
            })
            reject(error)
          })
      })
    }

    function resolveSelectedProfile() {
      const selectedProfileName = this.selected.name
      qtdUserByProfile.value = this.profileList.filter(profile => profile.name === selectedProfileName)[0].usersAssociated
      permissionsData.value = this.profileList.filter(profile => profile.name === selectedProfileName)[0].featuresProfiles
    }

    function validationForm() {
      this.$refs.createUser.validate().then(success => {
        if (success) {
          this.formSubmitted()
        }
      })
    }

    function formSubmitted() {
      return new Promise((resolve, reject) => {
        useJwt.createUser({
          name: this.name,
          email: this.emailUser,
          // password: this.newPasswordValue,
          // confirmPassword: this.RetypePassword,
          companyToken: this.userData.CompanyToken,
          cellPhone: removeSpecialChars(this.phone),
          cpf: removeSpecialChars(this.cpf),
          profileId: this.selected.id,
        })
          .then(response => {
            this.$swal({
              title: 'Tudo pronto!',
              text: 'Usuário criado com sucesso. Enviamos um email com as instruções de acesso.',
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            this.$router.push({ name: 'users-list' })

            resolve(response)
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              props: {
                // title: 'Ocorreu um erro ao tentar criar o usuário. Tente novamente',
                title: error.response.data.errors[0],
                icon: 'AlertOctagonIcon',
                variant: 'danger',
              },
            })
            reject(error)
          })
      })
    }

    return {
      permissionsData,
      // profileList,
      fetchProfileList,
      optionsProfileSelect,
      qtdUserByProfile,
      tableColumns,
      resolveSelectedProfile,
      validationForm,
      formSubmitted,
    }
  },
  methods: {
    togglePasswordOld() {
      this.passwordFieldTypeOld = this.passwordFieldTypeOld === 'password' ? 'text' : 'password'
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },
  },
}
</script>

<style lang="scss" scoped>

@import '@core/scss/vue/libs/vue-select.scss';

    // .modal-erros{
    //   display: flex;
    //   flex-direction: column;
    //   align-items: center;
    //   justify-content: center;
    //   text-align: center;

    //   h2{
    //     margin: 1rem 0;
    //     font-size: 1.4rem;
    //   }
    // }
</style>
