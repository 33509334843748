var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('validation-observer',{ref:"createUser"},[_c('b-overlay',{attrs:{"show":_vm.isAPICallInProgress,"rounded":"sm"}},[_c('b-card',[_c('b-form',{staticClass:"form-validate"},[_c('b-row',{staticClass:"mb-2"},[_c('b-col',{staticClass:"my-2",attrs:{"cols":"12"}},[_c('b-card-header',{staticClass:"p-1"},[_c('b-card-title',{staticClass:"font-medium-2"},[_c('feather-icon',{attrs:{"icon":"UserIcon","size":"18"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("Informações do novo usuário")])],1)],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Nome*","label-for":"name"}},[_c('validation-provider',{attrs:{"name":"nome","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"type":"text","placeholder":"Eleanor Aguiar"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Email*","label-for":"emailUser"}},[_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"type":"text","placeholder":"eleanoraguiar@gmail.com"},model:{value:(_vm.emailUser),callback:function ($$v) {_vm.emailUser=$$v},expression:"emailUser"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"CPF","label-for":"cpf"}},[_c('validation-provider',{attrs:{"name":"cpf"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control",attrs:{"id":"cpf","raw":false,"options":_vm.cpfMask,"placeholder":"xxx.xxx.xxx-xx"},model:{value:(_vm.cpf),callback:function ($$v) {_vm.cpf=$$v},expression:"cpf"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Telefone","label-for":"telefone"}},[_c('cleave',{staticClass:"form-control",attrs:{"id":"cellPhone","raw":false,"options":_vm.cellPhoneMask,"placeholder":"(xx) xxxxx-xxxx"},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})],1)],1),_c('b-col',{staticClass:"d-flex py-1 justify-content-end",attrs:{"cols":"8"}},[_c('b-button',{attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_c('span',{staticClass:"text-nowrap"},[_vm._v("Criar usuário")]),_c('feather-icon',{attrs:{"icon":"ArrowRightIcon","size":"18"}})],1)],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-card',{staticClass:"border mt-1",attrs:{"no-body":""}},[_c('b-row',{staticClass:"pt-2"},[_c('b-col',{attrs:{"cols":"3"}},[_c('b-card-header',{staticClass:"p-1"},[_c('b-card-title',{staticClass:"font-medium-2"},[_c('feather-icon',{attrs:{"icon":"LockIcon","size":"18"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("Permissões")])],1)],1)],1),_c('b-col',{attrs:{"cols":"3"}},[_c('validation-observer',{ref:"profileValidation"},[_c('b-form',{staticClass:"form-validate"},[_c('b-form-group',{attrs:{"label":"Escolha o perfil","label-for":"profileName"}},[_c('validation-provider',{attrs:{"name":"nome de perfil","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"name","options":_vm.optionsProfileSelect,"clearable":false},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-table',{staticClass:"mb-0",attrs:{"striped":"","responsive":"","items":_vm.permissionsData,"fields":_vm.tableColumns,"sort-desc":_vm.sortDesc,"sort-by":_vm.sortBy},on:{"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event}},scopedSlots:_vm._u([{key:"cell(featureName)",fn:function(data){return [_vm._v(" "+_vm._s(data.value)+" ")]}},{key:"cell(edit)",fn:function(data){return [_c('b-form-checkbox',{attrs:{"checked":data.value,"disabled":""}})]}},{key:"cell(read)",fn:function(data){return [_c('b-form-checkbox',{attrs:{"checked":data.value,"disabled":""}})]}}])})],1)],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }